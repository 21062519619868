<template>
    <div>
        <div class="ft20 cl-main ftw600">工单订单</div>

        <div class="mt20">
            <div class="form-search-box ">
                <a-form layout="inline">
                    <a-form-item label="筛选项" style="width: 440px">
                        <a-input-group style="width: 100%" compact>
                            <a-select v-model="search.key" style="width: 50%" :options="search_list">
                            </a-select>
                            <a-input v-model="search.value" style="width: 50%"/>
                        </a-input-group>
                    </a-form-item>

                    <a-form-item label="客户">
                        <a-input v-model="search.member" placeholder="请输入客户姓名/手机号"></a-input>
                    </a-form-item>

                    <a-form-item label="预约时间">
                        <a-range-picker @change="changeAppointTime" v-model="search.appoint_time" format="YYYY-MM-DD"
                                        valueFormat="YYYY-MM-DD"/>
                    </a-form-item>

                    <a-form-item label="支付方式">
                        <a-select style="width: 100px" v-model="search.pay_type">
                            <a-select-option value="">
                                全部
                            </a-select-option>
                            <a-select-option value="cyfpay">
                                重阳分
                            </a-select-option>
                            <a-select-option value="offline">
                                现金
                            </a-select-option>
                            <a-select-option value="merge">
                                混合
                            </a-select-option>
                            <a-select-option value="weixin">
                                微信支付
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item label="项目分类">
                        <a-select v-model="search.project_type_id" style="width: 240px;">
                            <a-select-option :value="0">全部分类</a-select-option>
                            <a-select-option :value="item.project_type_id" v-for="(item,index) in type_list"
                                             :key="index">{{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item>
                        <a-button @click="searchAct" type="primary">查询</a-button>
                        <a-button @click="cancelAct" class="ml10">取消</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </div>

        <div class="mt20">
            <div class="pd30 bg-w " style="min-height: 800px;">
                <div>
                    <a-button type="primary" icon="plus" @click="addAppointmentAct()">新增工单</a-button>
                </div>

                <div class="mt20">
                    <div>
                        <a-menu v-model="current" mode="horizontal">
                            <a-menu-item :key="item.key" @click="clickmenu" v-for="(item,index) in status_lists">
                                {{ item.name }}
                            </a-menu-item>
                        </a-menu>
                    </div>


                    <div class="wxb-table-gray">
                        <a-table rowKey="appointment_id" :columns="columns" :pagination="pagination"
                                 @change="handleTableChange"
                                 :data-source="datas" :loading="loading">

                            <div style="text-align: center;" slot="day" slot-scope="day,record">
                                <div>
                                    <div style="display: contents" class="ft12 ftw400 ">{{ day }}</div>
                                    <div class="cl-theme">{{ record.start_time }}-{{ record.end_time }}</div>
                                </div>
                            </div>

                            <div class="flex alcenter left" slot="shop" slot-scope="shop,index">
                                <a-tag v-if="shop.name" color="purple">
                                    {{ shop.name }}
                                </a-tag>
                                <a-tag v-else color="green">平台</a-tag>
                            </div>

                            <div class="flex alcenter left" slot="project_id" slot-scope="project_id,record">
                                <img :src="record.project_cover_img" class="projetc-cover-img"/>
                                <div class="ml10 " style="text-align: left;">
                                    <div class="text-over4">{{ record.project_name }}</div>
                                    <div v-if="record.project_sku_name!=''" class="flex alcenter">
                                        <div class="ft12 ftw400 cl-notice">规格：</div>
                                        <div class="ft12 ftw400 cl-notice text-over4">{{ record.project_sku_name }}
                                        </div>
                                    </div>
                                    <div class="flex alcenter">
                                        <div class="ft12 ftw400 cl-notice">价格：</div>
                                        <div class="ft12 ftw400 cl-notice text-over4">{{ record.total_price }}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="flex alcenter left" slot="elder" slot-scope="elder,record">
                                <div class="flex alcenter center" v-if="elder">

                                    <div class="ml5" style="text-align: left;">
                                        <div>{{ elder.name }}</div>
                                        <div class="mt2">{{ elder.mobile }}</div>
                                    </div>
                                </div>
                                <div class="flex alcenter center" v-else>本人订购</div>
                            </div>

                            <div class="flex alcenter left" slot="serviceman" slot-scope="serviceman,record">
                                <div class="flex alcenter center" v-if="serviceman">
                                    <img v-if="serviceman.face!=null" :src="serviceman.face" class="member-index-face"/>
                                    <img v-else src="../../assets/image/woyaoyou_head_defult@2x.png"
                                         class="member-index-face"/>
                                    <div class="ml5" style="text-align: left;">
                                        <div>{{ serviceman.name }}</div>
                                        <div class="mt2">{{ serviceman.mobile }}</div>
                                    </div>
                                </div>
                                <div class="flex alcenter center" v-else>待分配</div>
                            </div>

                            <div class="flex alcenter center" slot="status" slot-scope="status,record">
                                <div v-if="status==1 || status==4 || status==3">{{ record.status_means }}</div>
                                <div v-if="status==8 || status==-1" class="cl-notice">{{ record.status_means }}</div>
                            </div>

                            <div class="flex alcenter center" slot="is_paid" slot-scope="is_paid,record">
                                {{ is_paid == 1 ? '已支付' + record.need_pay : '未支付' }}
                            </div>

                            <template slot="action" slot-scope="record,index">
                                <div class="flex center">
                                    <a-dropdown placement="bottomRight">
										<span class="more-act">
											<i class="iconfont iconmore_gray"></i>
										</span>
                                        <a-menu slot="overlay">
                                            <a-menu-item>
                                                <a class="menu-act" href="javascript:;" @click="showDetailAct(record)">
                                                    <i class="iconfont ft14 iconsee"></i>
                                                    <span class="ml10">查看详情</span>
                                                </a>
                                            </a-menu-item>
                                            <a-menu-item v-if="record.status==1">
                                                <a class="menu-act" href="javascript:;"
                                                   @click="appointAppointAct(record)">
                                                    <i class="iconfont ft14 iconorder_operation_confirm"></i>
                                                    <span v-if="record.serviceman_id==0" class="ml10">指派服务人</span>
                                                    <span else-if="record.serviceman_id!=0"
                                                          class="ml10">更换服务人</span>
                                                </a>
                                            </a-menu-item>
                                            <a-menu-item v-if="record.status==1 && record.serviceman_id!=0">
                                                <a class="menu-act" href="javascript:;" @click="sureAppointAct(record)">
                                                    <i class="iconfont ft14 iconorder_operation_confirm"></i>
                                                    <span class="ml10">确认预约</span>
                                                </a>
                                            </a-menu-item>
                                            <a-menu-item v-if="record.status==3">
                                                <a class="menu-act" href="javascript:;"
                                                   @click="startServiceAct(record)">
                                                    <i class="iconfont ft14 iconorder_operation_startservice"></i>
                                                    <span class="ml10">开始服务</span>
                                                </a>
                                            </a-menu-item>
                                            <a-menu-item v-if=" record.status==4">
                                                <a class="menu-act" href="javascript:;"
                                                   @click="completeAppointAct(record)">
                                                    <i class="iconfont ft14 iconorder_operation_cancle"></i>
                                                    <span class="ml10">完成服务</span>
                                                </a>
                                            </a-menu-item>
                                            <a-menu-item v-if="record.status==1 || record.status==3">
                                                <a class="menu-act" href="javascript:;"
                                                   @click="cancelAppointAct(record)">
                                                    <i class="iconfont ft14 iconorder_operation_cancle"></i>
                                                    <span class="ml10">取消预约</span>
                                                </a>
                                            </a-menu-item>

                                        </a-menu>
                                    </a-dropdown>
                                </div>
                            </template>
                        </a-table>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="addAppointmentVisible">
            <add-appointment :visible="addAppointmentVisible" :shop-id="shop_id" @cancel="cancelAddAppointment"
                             @ok="okAddAppointment"></add-appointment>
        </div>
        <!-- <div v-if="showDetailVisible">
          <order-detail :visible="showDetailVisible" :order="order" @cancel="cancelShowDetail" @sureAppoint="sureAppoint"
          @cancelAppoint="cancelAppoint" @sureWriteOff="sureWriteOff"></order-detail>
        </div> -->
        <div v-if="cancelAppointVisible">
            <cancel-appoint :appointment_id="appointment_id" :visible="cancelAppointVisible"
                            @cancel="cancelCancelAppoint" @ok="okCancelAppoint"></cancel-appoint>
        </div>
        <div v-if="distributionServicemanVisible">
            <distribution-serviceman :appointment_id="appointment_id" :visible="distributionServicemanVisible"
                                     @cancel="cancelDistributionServiceman"
                                     @ok="okDistributionServiceman"></distribution-serviceman>
        </div>

        <div v-if="completeAppointVisible">
            <complete-appoint :order="order" :visible="completeAppointVisible" @cancel="cancelCompleteAppoint"
                              @ok="okCompleteAppoint"></complete-appoint>
        </div>
    </div>
</template>

<script>
import {
    listMixin
} from '../../common/mixin/list.js';
import addAppointment from './components/order/modal/addAppointment.vue';
import cancelAppoint from './components/order/modal/detail/modal/cancelAppoint.vue';
import completeAppoint from './components/order/modal/completeAppoint.vue';
import distributionServiceman from './components/order/modal/distributionServiceman.vue';
import moment from "moment";

export default {
    mixins: [listMixin],
    components: {
        addAppointment,
        cancelAppoint,
        distributionServiceman,
        completeAppoint,
    },
    data() {
        return {
            loading: false,
            addAppointmentVisible: false,
            // showDetailVisible:false,
            cancelAppointVisible: false,
            distributionServicemanVisible: false,
            completeAppointVisible: false,
            order: {},
            shop_id: 0,
            search_list: [],
            appointment_id: 0,
            pagination: {
                current: 1,
                pageSize: 10, //每页中显示10条数据
                total: 0,
                showTotal: false,
            },
            status_lists: null,
            current: ['all'],
            search: {
                appointment_id: '',
                member: '',
                appoint_time: [],
                key: 'appointment_id',
                status: 'all',
                project_type_id: 0,
                value: '',
                pay_type: '',
            },
            type_list: [],
            columns: [],
            datas: [],
        }
    },
    created() {
        this.loaddata();
    },
    methods: {
        loaddata() {
            this.$http.api('admin/getAppointmenconfig', {}).then(res => {
                if (res.config.shop_id) {
                    this.shop_id = res.config.shop_id;
                }
            });
        },
        cancelAct() {

            this.search.appointment_id = '';
            this.search.member = '';
            this.search.appoint_time = [];
            this.search.project_type_id = 0;
            this.search.value = '';
            this.search.pay_type = '';

            this.getLists();
        },
        getLists() {
            console.log(this.$route.query)
						if(this.$route.query && this.isPageInit){
                if(this.$route.query.inquire === 'toDay'){
                    this.search.appoint_time = [
                        moment().format('YYYY-MM-DD'),
                        moment().format('YYYY-MM-DD')
                    ];
                }
                if(this.$route.query.inquire === 'thisMonth'){
                    this.search.appoint_time = [
                        moment().subtract(30, 'days').format('YYYY-MM-DD'),
                        moment().format('YYYY-MM-DD')
                    ];
                }
						}
            if (this.loading == true) return;
            this.loading = true;
            this.$http.api('admin/getAppointmentList', {
                limit: this.pagination.pageSize,
                page: this.pagination.current,

                member: this.search.member,
                search_key: this.search.key,
                search_value: this.search.value,
                appoint_time: this.search.appoint_time,
                project_type_id: this.search.project_type_id,
                status: this.search.status,
                pay_type: this.search.pay_type,
            }).then(res => {
                this.pagination.total = res.total;
                this.pagination.showTotal = (total) => "共" + res.total + "条";
                this.datas = res.list;
                this.status_lists = res.status_lists;
                this.search_list = res.search_list;
                if (res.type_list) {
                    this.type_list = res.type_list;
                }
                if (this.columns.length <= 0) {
                    this.columns = res.columns;
                }
                this.loading = false;
            }).catch(res => {
                console.log(res);
                this.loading = false;
            })
        },
        clickmenu(e) {
            this.search.status = e.key;
            this.getLists();
        },
        appointAppointAct(record) {
            this.distributionServicemanAct(record);
        },
        sureAppointAct(record) {
            if (record.serviceman_id != 0) {
                this.$confirm({
                    title: '确认这个预约吗？',
                    okText: "确定",
                    okType: "danger",
                    cancelText: "取消",
                    onOk: () => {
                        return new Promise((resolve, reject) => {
                            this.$http.api('admin/sureAppointment', {
                                appointment_id: record.appointment_id,
                            }).then(res => {
                                this.$message.success('确认成功');
                                this.getLists();
                            }).catch(res => {
                                console.log(res);
                            }).finally(() => {
                                resolve();
                            })
                        })
                    }
                })
            } else {
                this.distributionServicemanAct(record);
            }
        },

        distributionServicemanAct(record) {
            this.appointment_id = record.appointment_id;
            this.distributionServicemanVisible = true;
        },
        cancelDistributionServiceman() {
            this.distributionServicemanVisible = false;
        },
        okDistributionServiceman() {
            this.distributionServicemanVisible = false;
            this.getLists();
        },

        startServiceAct(record) {
            this.$confirm({
                title: '确认开始服务了吗？',
                okText: "确定",
                okType: "danger",
                cancelText: "取消",
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.$http.api('admin/startService', {
                            appointment_id: record.appointment_id,
                        }).then(res => {
                            this.$message.success('操作成功');
                            this.getLists();
                        }).catch(res => {
                            console.log(res);
                        }).finally(() => {
                            resolve();
                        })
                    })
                }
            })
        },

        //完成服务
        completeAppointAct(record) {
            this.order = record;
            this.completeAppointVisible = true;
        },
        cancelCompleteAppoint() {
            this.completeAppointVisible = false;
        },
        okCompleteAppoint() {
            this.completeAppointVisible = false;
            this.getLists();
        },

        //新增预约
        addAppointmentAct() {
            this.addAppointmentVisible = true;
        },
        cancelAddAppointment() {
            this.addAppointmentVisible = false;
        },
        okAddAppointment() {
            this.addAppointmentVisible = false;
            this.getLists();
        },

        //查看订单详情
        showDetailAct(record) {
            this.$router.push('/appointment/orderDetail?id=' + record.appointment_id)
        },

        //取消预约
        cancelAppointAct(record) {
            this.appointment_id = record.appointment_id;
            this.cancelAppointVisible = true;
        },
        cancelCancelAppoint() {
            this.cancelAppointVisible = false;
        },
        okCancelAppoint() {
            this.cancelAppointVisible = false;
            this.getLists();
        },


        changeAppointTime(value) {
            this.search.appoint_time = value;
        },

        handleTableChange(pagination, filters, sorter) {
            this.pagination.current = pagination.current;
            this.getLists();
        },
    }
}
</script>

<style>

.member-index-face {
    width: 44px;
    height: 44px;
    border-radius: 44px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
    border: 2px solid #FFFFFF;
}

.projetc-cover-img {
    width: 80px;
    height: 60px;
    float: left;
}

.member-face {
    width: 40px;
    height: 40px;
    border-radius: 20px;
}
</style>
